import React, { useEffect } from "react";
import {
    Paper,
    Button,
    Grid,
    Typography,
    Divider,
    Card,
    CardMedia,
    CardContent,
    CardActions,
} from "@mui/material";

import hexagonSrc from "../assets/hexagon.png";
import ellipseSrc from "../assets/ellipse.png";
import { useNavigate, useLocation } from "react-router-dom";
const ChooseFormat = ({ setStepper }) => {
    const navigate = useNavigate();
    
    const locationState = useLocation();
    const model = locationState.state?.model;
    const handleOptionClick = (option) => {
        navigate("/configure-orthosis", { state: { pattern: option, model: model } });
        setStepper(1);
    };
    useEffect(() => {
        setStepper(1);
        if (!model) {
          navigate("/");
        }
        // eslint-disable-next-line
    }, []);
    return (
        <Paper
            sx={{
                width: {
                    xl:"65vw",
                    lg:'85vw',
                    xs:'95vw'
                  },
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                flexDirection: "column",
                my: 15,
                borderRadius: 4,
                px: 5,
                py: 3,
            }}
            elevation={10}
        >
            <Typography variant="h4" fontWeight={400} pb={1}>
               Select the pattern:
            </Typography>
            <Divider sx={{ mb: 5 }} />
            <Grid
                container
                spacing={10}
                alignContent={"center"}
                justifyContent={"center"}
            >
                <Grid item>
                    <Card elevation={5} sx={{ borderRadius: 3 }}>
                        <CardMedia
                            sx={{
                                height: 200,
                                width: "100%",
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                my: 2,
                            }}
                            image={hexagonSrc}
                            title="hexagon"
                        />
                        <Divider sx={{ py: 1, px: 20 }} />
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                fontWeight={500}
                                textAlign="center"
                            >
                                Hexagon
                            </Typography>
                            
                        </CardContent>
                        <CardActions>
                            <Button
                                variant="contained"
                                fullWidth
                                color="primary"
                                onClick={() => handleOptionClick("hexagon")}
                                sx={{ mx: 1 }}
                            >
                                Select
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item>
                    <Card elevation={5} sx={{ borderRadius: 3 }}>
                        <CardMedia
                            sx={{
                                height: 200,
                                width: "100%",
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                my: 2,
                            }}
                            image={ellipseSrc}
                            title="ellipse"
                        />
                        <Divider sx={{ py: 1, px: 20 }} />
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                fontWeight={500}
                                textAlign="center"
                            >
                                Ellipse
                            </Typography>
                            
                        </CardContent>
                        <CardActions>
                            <Button
                                variant="contained"
                                fullWidth
                                color="primary"
                                onClick={() => handleOptionClick("ellipse")}
                                sx={{ mx: 1 }}
                            >
                                Select
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ChooseFormat;
