import React, { useEffect } from "react";
import {
  Paper,
  Button,
  Grid,
  Typography,
  Divider,
  Card,
  CardMedia,
  CardContent,
  CardActions,
} from "@mui/material";

import profil01Src from "../assets/type01.png";
import profil02Src from "../assets/type02.png";
import { useNavigate } from "react-router-dom";
const ChooseModel = ({ setStepper }) => {
  const navigate = useNavigate();

  const handleOptionClick = (option) => {
    navigate("/select-pattern", { state: { model: option } });
    setStepper(1);
  };
  useEffect(() => {
    setStepper(0);
    // eslint-disable-next-line
  }, []);
  return (
    <Paper
      sx={{
        width: {
          xl:"65vw",
          lg:'85vw',
          xs:'95vw'
        },
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        flexDirection: "column",
        my: 15,
        borderRadius: 4,
        px: 5,
        py: 3,
      }}
      elevation={10}
    >
      <Typography variant="h4" fontWeight={400} pb={1}>
        Select orthosis type:
      </Typography>
      <Divider sx={{ mb: 5 }} />
      <Grid
        container
        spacing={10}
        alignContent={"center"}
        justifyContent={"center"}
      >
        <Grid item>
          <Card elevation={5} sx={{ borderRadius: 3 }}>
            <CardMedia
              sx={{
                height: 200,
                width: "100%",
                backgroundSize: "contain",
                backgroundPosition: "center",

                mt: 3,
                px: 20,
                borderRadius: 4,
              }}
              image={profil01Src}
              title="hexagon"
            />
            <Divider sx={{ py: 1, px: 20 }} />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                fontWeight={500}
                textAlign="center"
              >
                Type 1
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => handleOptionClick("model01")}
                sx={{ mx: 1 }}
              >
                Select
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item>
          <Card elevation={5} sx={{ borderRadius: 3 }}>
            <CardMedia
              sx={{
                height: 200,
                width: "100%",
                backgroundSize: "contain",
                backgroundPosition: "center",
                px: 20,
                mt:3,
                borderRadius: 4,
              }}
              image={profil02Src}
              title="ellipse"
            />
            <Divider sx={{ py: 1, px: 20 }} />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                fontWeight={500}
                textAlign="center"
              >
                Type 2
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => handleOptionClick("model02")}
                sx={{ mx: 1 }}
              >
                Select
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ChooseModel;
