import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ChooseFormat from "./views/ChooseFormat";
import "./index.css";
import { AppBar, Toolbar, Stepper, Step, StepLabel, Box } from "@mui/material";
import StepConnector, {
    stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Check from "@mui/icons-material/Check";
import SetParameters from "./views/SetParameters";
import { Link } from "react-router-dom";
import DownloadStep from "./views/DownloadStep";
import { SnackbarProvider } from "notistack";
import ChooseModel from "./views/ChooseModel";
const steps = ["Select Type", "Select Pattern", "Configure Orthosis", "Download Model"];

function App() {
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: "calc(-50% + 16px)",
            right: "calc(50% + 16px)",
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: "#333",
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: "#784af4",
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: "#eaeaf0",
            borderTopWidth: 3,
            borderRadius: 1,
        },
    }));

    const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
        color: "#eaeaf0",
        display: "flex",
        height: 22,
        alignItems: "center",
        ...(ownerState.active && {
            color: "#30ff33",
        }),
        "& .QontoStepIcon-completedIcon": {
            color: "#333",
            zIndex: 1,
            fontSize: 18,
        },
        "& .QontoStepIcon-circle": {
            width: 8,
            height: 8,
            borderRadius: "50%",
            backgroundColor: "currentColor",
        },
    }));

    function QontoStepIcon(props) {
        const { active, completed, className } = props;

        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <Check className="QontoStepIcon-completedIcon" />
                ) : (
                    <div className="QontoStepIcon-circle" />
                )}
            </QontoStepIconRoot>
        );
    }

    QontoStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
    };
    useEffect(() => {
        setActiveStepIndex(
            steps.findIndex(
                (step) =>
                    `/${step.toLowerCase().replace(" ", "-")}` ===
                    window.location.pathname
            ) === -1
                ? 0
                : steps.findIndex(
                      (step) =>
                          `/${step.toLowerCase().replace(" ", "-")}` ===
                          window.location.pathname
                  )
        );
        // eslint-disable-next-line
    }, [window.location.pathname]);
    return (
        <Router>
            <SnackbarProvider
                maxSnack={5}
                autoHideDuration={4000}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                style={{
                    width: 500,
                }}
            >
                <AppBar position="static" sx={{ py: 2 }}>
                    <Toolbar>
                        <Stepper
                            activeStep={activeStepIndex}
                            alternativeLabel
                            sx={{ width: "100%" }}
                            connector={<QontoConnector />}
                        >
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel color="white">
                                        {index <= activeStepIndex ? (
                                            <Link
                                                to={`/${label
                                                    .toLowerCase()
                                                    .replace(" ", "-")}`}
                                                style={{
                                                    cursor: "pointer",
                                                    color: "white",
                                                    textDecoration: "none",
                                                }}
                                            >
                                                {label}
                                            </Link>
                                        ) : (
                                            label
                                        )}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Toolbar>
                </AppBar>

                <Box className={"main-container"}>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <ChooseModel setStepper={setActiveStepIndex} />
                            }
                        />
                        <Route
                            path="/select-type"
                            element={
                                <ChooseModel setStepper={setActiveStepIndex} />
                            }
                        />
                        <Route
                            path="/select-pattern"
                            element={
                                <ChooseFormat setStepper={setActiveStepIndex} />
                            }
                        />
                        <Route
                            path="/configure-orthosis"
                            element={
                                <SetParameters
                                    setStepper={setActiveStepIndex}
                                />
                            }
                        />
                        <Route
                            path="/download-step"
                            element={
                                <DownloadStep setStepper={setActiveStepIndex} />
                            }
                        />
                    </Routes>
                </Box>
            </SnackbarProvider>
        </Router>
    );
}

export default App;
