import React, { Fragment, useEffect, useState } from "react";
import {
  Paper,
  Button,
  Typography,
  Divider,
  TextField,
  Box,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import api from "../utils/api";
import { API_ENDPOINTS } from "../utils/vars";

import hexagonPattern from "../assets/hexagon_pattern.png";
import ellipsePattern from "../assets/ellipse_pattern.png";
import profil01Src from "../assets/profil01.png";
import profil02Src from "../assets/profil02.png";
import Loading from "../utils/Loading";

const SetParameters = ({ setStepper }) => {
  const locationState = useLocation();
  const [params, setParams] = useState({
    model01: {
      hForearmCircumference: 240,
      i: 20,
      ABForearmLength: 270,
      eMVDistance: 84,
      MIIMVDistance: 65,
      eMIIDistance: 100,
      eWristCircumference: 196,
      hOrthosisThickness: 3,
      tMajorAxis: 40,
      tMinorAxis: 25,
      tAngle: 10,
    },
    model02: {
      hForearmCircumference: 240,
      i: 20,
      ABForearmLength: 270,
      eMVDistance: 84,
      MIIMVDistance: 65,
      eMIIDistance: 100,
      eWristCircumference: 196,
      hOrthosisThickness: 3,
      lowestPointDistance: 30,
      palmThumbDistance: 80,
    },
    hexagon: {
      sHexagon: 10,
      wt:3,
    },
    ellipse: {
      majorAxis: 16,
      minorAxis: 12,
      wt:3,
    },
  });
  const [loading, setLoading] = useState(false)
  const pattern = locationState.state?.pattern;
  const model = locationState.state?.model;
  const navigate = useNavigate();
  useEffect(() => {
    setStepper(2);
    if (!pattern || !model) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [pattern, model]);
  const sendParams = async () => {

    setLoading(true)
    let invalidParam = false;
    Object.keys(params[model]).map((key) => {
      if (
        params[model][key] <= 0 ||
        !params[model][key] 
      ) {
        enqueueSnackbar(`Parameter ${key} should be greater than 0.`, {
          variant: "warning",
        });
        invalidParam = true;
        return true;
      }
      return false;
    });
    if (!invalidParam) {
      const startTime = Date.now();
      try {
        const response = await api.post(API_ENDPOINTS.GENERATE_MODEL, {
          pattern: pattern,
          model: model,
          ...params[pattern],
          ...params[model],
        });
        if (response.data.status) {
          const endTime = Date.now();
          const duration = (endTime - startTime) / 1000;
          setLoading(false)
          navigate("/download-step", { state: { files: response.data?.files, duration:duration } });
        }
      } catch (error) {
        console.error("Error changing status:", error);
      } finally {
        setStepper(3);
        setLoading(false)
      }
    }
  };
  const handleInputChange = (ev,param, paramKey) => {
    const inputValue = ev.target.value;
    
    setParams((currParams) => ({
      ...currParams,

      [param]: { ...currParams[param], [paramKey]: inputValue },
    }));
  };
  const handleBlurChange = (ev, param, paramKey) => {
    const inputValue = ev.target.value;
    if(paramKey === "lowestPointDistance" && (inputValue < 30 || inputValue > 60) ){
      enqueueSnackbar("Please enter value between 30 and 60 for the lowest point.", {
        variant: "warning",
      });
    }
    if (inputValue >= 0 || ev.target.value === "") {
      setParams((currParams) => ({
        ...currParams,
        [param]: {
          ...currParams[param],
          [paramKey]: parseFloat(inputValue),
        },
      }));
    } else {
      enqueueSnackbar("Please enter a valid number, greater than 0.", {
        variant: "warning",
      });
      setParams((currParams) => ({
        ...currParams,
        [param]: { ...currParams[param], [paramKey]: "" },
      }));
    }
  };
  if(loading){
    return(
      <Loading/>
    )
  }
  return (
    <Paper
      sx={{
        width: {
          xl:"65vw",
          lg:'95vw',
          xs:'95vw'
        },
        display: "grid",
        my: 15,
        borderRadius: 4,
        px: 5,
        py: 3,
      }}
      elevation={10}
    >
      <Typography variant="h4" fontWeight={400} pb={1}>
        Set the parameters for the {pattern} pattern and type {model.split("0")[1]}{" "}
        orthosis:
      </Typography>
      <Divider sx={{ mb: 5 }} />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            mb: 2,
          }}
        >
          {pattern === "hexagon" ? (
            <>
              <TextField
                margin="normal"
                required
                type="number"
                fullWidth
                id={"S Hexagon"}
                label={"S Hexagon"}
                inputProps={{ min: 0 }}
                value={params[pattern].sHexagon}
                onChange={(ev) => handleInputChange(ev, pattern, "sHexagon")}
                onBlur={(ev) => handleBlurChange(ev,pattern, "sHexagon")}
              />
              <TextField
                margin="normal"
                required
                type="number"
                fullWidth
                id={"wt"}
                label={"Wall thickness"}
                inputProps={{ min: 0 }}
                value={params[pattern].wt}
                onChange={(ev) => handleInputChange(ev, pattern, "wt")}
                onBlur={(ev) => handleBlurChange(ev,pattern, "wt")}
              />
            </>
          ) : (
            <>
              <TextField
                margin="normal"
                required
                type="number"
                fullWidth
                id={"Major axis for pattern elipse"}
                label={"Major axis for pattern elipse"}
                inputProps={{ min: 0 }}
                value={params[pattern].majorAxis}
                onChange={(ev) => handleInputChange(ev, pattern,"majorAxis")}
                onBlur={(ev) => handleBlurChange(ev, pattern, "majorAxis")}
              />
              <TextField
                margin="normal"
                required
                type="number"
                fullWidth
                id={"Minor axis for pattern ellipse"}
                label={"Minor axis for pattern ellipse"}
                inputProps={{ min: 0 }}
                value={params[pattern].minorAxis}
                onChange={(ev) => handleInputChange(ev, pattern, "minorAxis")}
                onBlur={(ev) => handleBlurChange(ev, pattern, "minorAxis")}
              />
              <TextField
                margin="normal"
                required
                type="number"
                fullWidth
                id={"wt"}
                label={"Wall thickness"}
                inputProps={{ min: 0 }}
                value={params[pattern].wt}
                onChange={(ev) => handleInputChange(ev, pattern, "wt")}
                onBlur={(ev) => handleBlurChange(ev,pattern, "wt")}
              />
            </>
          )}
          <Divider sx={{mt:2, mb:1}}/>
          <TextField
            margin="normal"
            required
            type="number"
            fullWidth
            id={"h - forearm circumference"}
            label={"h - forearm circumference"}
            inputProps={{ min: 0 }}
            value={params[model].hForearmCircumference}
            onChange={(ev) => handleInputChange(ev, model, "hForearmCircumference")}
            onBlur={(ev) => handleBlurChange(ev, model, "hForearmCircumference")}
          />
          <TextField
            margin="normal"
            required
            type="number"
            fullWidth
            id={"i"}
            label={"i"}
            inputProps={{ min: 0 }}
            value={params[model].i}
            onChange={(ev) => handleInputChange(ev, model, "i")}
            onBlur={(ev) => handleBlurChange(ev, model, "i")}
          />{" "}
          <TextField
            margin="normal"
            required
            type="number"
            fullWidth
            id={"AB - forearm length"}
            label={"AB - forearm length"}
            inputProps={{ min: 0 }}
            value={params[model].ABForearmLength}
            onChange={(ev) => handleInputChange(ev, model, "ABForearmLength")}
            onBlur={(ev) => handleBlurChange(ev, model, "ABForearmLength")}
          />{" "}
          <TextField
            margin="normal"
            required
            type="number"
            fullWidth
            id={"e - M V distance"}
            label={"e - M V distance"}
            inputProps={{ min: 0 }}
            value={params[model].eMVDistance}
            onChange={(ev) => handleInputChange(ev, model, "eMVDistance")}
            onBlur={(ev) => handleBlurChange(ev, model, "eMVDistance")}
          />{" "}
          <TextField
            margin="normal"
            required
            type="number"
            fullWidth
            id={"M II - M V distance"}
            label={"M II - M V distance"}
            inputProps={{ min: 0 }}
            value={params[model].MIIMVDistance}
            onChange={(ev) => handleInputChange(ev, model, "MIIMVDistance")}
            onBlur={(ev) => handleBlurChange(ev, model, "MIIMVDistance")}
          />{" "}
          <TextField
            margin="normal"
            required
            type="number"
            fullWidth
            id={"e - M II distance"}
            label={"e - M II distance"}
            inputProps={{ min: 0 }}
            value={params[model].eMIIDistance}
            onChange={(ev) => handleInputChange(ev, model, "eMIIDistance")}
            onBlur={(ev) => handleBlurChange(ev, model, "eMIIDistance")}
          />
          <TextField
            margin="normal"
            required
            type="number"
            fullWidth
            id={"e - wrist circumference"}
            label={"e - wrist circumference"}
            inputProps={{ min: 0 }}
            value={params[model].eWristCircumference}
            onChange={(ev) => handleInputChange(ev, model, "eWristCircumference")}
            onBlur={(ev) => handleBlurChange(ev, model, "eWristCircumference")}
          />
          <TextField
            margin="normal"
            required
            type="number"
            fullWidth
            id={"orthosis thickness"}
            label={"orthosis thickness"}
            inputProps={{ min: 0 }}
            value={params[model].hOrthosisThickness}
            onChange={(ev) => handleInputChange(ev, model, "hOrthosisThickness")}
            onBlur={(ev) => handleBlurChange(ev, model, "hOrthosisThickness")}
          />
          {model === "model01" ? (
            <>
              <TextField
                margin="normal"
                required
                type="number"
                fullWidth
                id={"t major axis"}
                label={"t major axis"}
                inputProps={{ min: 0 }}
                value={params[model].tMajorAxis}
                onChange={(ev) => handleInputChange(ev, model, "tMajorAxis")}
                onBlur={(ev) => handleBlurChange(ev, model, "tMajorAxis")}
              />
              <TextField
                margin="normal"
                required
                type="number"
                fullWidth
                id={"t minor axis"}
                label={"t minor axis"}
                inputProps={{ min: 0 }}
                value={params[model].tMinorAxis}
                onChange={(ev) => handleInputChange(ev, model, "tMinorAxis")}
                onBlur={(ev) => handleBlurChange(ev, model, "tMinorAxis")}
              />

              <TextField
                margin="normal"
                required
                type="number"
                fullWidth
                id={"t angle"}
                label={"t angle"}
                inputProps={{ min: 0 }}
                value={params[model].tAngle}
                onChange={(ev) => handleInputChange(ev, model, "tAngle")}
                onBlur={(ev) => handleBlurChange(ev, model, "tAngle")}
              />
            </>
          ) : (
            <>
              <TextField
                margin="normal"
                required
                type="number"
                fullWidth
                id={"lowest point distance"}
                label={"lowest point distance"}
                inputProps={{ min: 0 }}
                value={params[model].lowestPointDistance}
                onChange={(ev) => handleInputChange(ev, model, "lowestPointDistance")}
                onBlur={(ev) => handleBlurChange(ev, model, "lowestPointDistance")}
              />
              <TextField
                margin="normal"
                required
                type="number"
                fullWidth
                id={"palm thumb distance"}
                label={"palm thumb distance"}
                inputProps={{ min: 0 }}
                value={params[model].palmThumbDistance}
                onChange={(ev) => handleInputChange(ev, model, "palmThumbDistance")}
                onBlur={(ev) => handleBlurChange(ev, model, "palmThumbDistance")}
              />
            </>
          )}
        </Box>
        <Box sx={{ mb:20}}>
       
        <Box
          sx={{
            width:'100%',
            marginTop: "16px",
            py: 15,
            backgroundImage: `url(${
              pattern === "hexagon" ? hexagonPattern : ellipsePattern
            })`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition:'center'
          }}
          />
           <Box
          sx={{
            width: "550px",
            backgroundImage: `url(${
              model === "model01" ? profil01Src : profil02Src
            })`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height:'80%',
            backgroundPosition: "center",
          }}
          />
        </Box>
      </Box>
      <Divider />
      <Button
        variant="contained"
        fullWidth
        color="primary"
        onClick={sendParams}
        sx={{ width: 300, justifySelf: "self-end", mt: 5 }}
      >
        Generate files
      </Button>
    </Paper>
  );
};

export default SetParameters;
