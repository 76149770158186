import React, { useEffect } from "react";
import {
  Paper,
  Divider,
  Typography,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import api from "../utils/api";
import { API_ENDPOINTS } from "../utils/vars";
const DownloadStep = ({ setStepper }) => {
  const locationState = useLocation();
  const files = locationState.state?.files;
  const duration = locationState.state?.duration;

  const navigate = useNavigate();
  const downloadFile = async (file) => {
    try {
      const response = await api.get(API_ENDPOINTS.DOWNLOAD_MODEL, {
        params: { filename: file.name },
        responseType: "blob",
      });
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error changing status:", error);
    } finally {
      setStepper(4);
    }
  };
  useEffect(() => {
    setStepper(3);
    // eslint-disable-next-line
  }, []);
  const generateNewModel = () => {
    navigate("/");
    setStepper(0);
  };
  function formatDuration(duration) {
    if (duration < 60) {
      return `${Math.round(duration)} seconds`;
    } else {
      const minutes = Math.floor(duration / 60);
      const seconds = Math.round(duration % 60);
      return `${minutes} minute${minutes > 1 ? 's' : ''} and ${seconds} second${seconds !== 1 ? 's' : ''}`;
    }
  }
  return (
    <Box>
      <Paper
        sx={{
            width: {
                xl:"65vw",
                lg:'85vw',
                xs:'95vw'
              },
          display: "grid",
          my: 15,
          borderRadius: 4,
          px: 5,
          py: 3,
        }}
        elevation={10}
      >
        <Typography variant="h4" fontWeight={400} pb={1}>
          Files:
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {files?.map((file) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Typography variant="body1" fontWeight={500} style={{ flexGrow: 1 }}>
                  {file?.name} -{" "}
                  {file?.size > 0
                    ? parseFloat(file?.size / 1_000_000).toFixed(4) + " MB"
                    : "loading file information..."}
                </Typography>
                <IconButton
                  color="success"
                  aria-label="download file"
                  onClick={() => downloadFile(file)}
                >
                  <DownloadIcon />
                </IconButton>
              </div>
            );
          })}
        </div>
        <Divider/>
        <Typography
          variant="body1"
          fontWeight={"700"}
          fontSize={14}
          color={"#2e7d32"}
          mt={3}
        >
          Please be aware of the paper format and the print scale(1:1)!
        </Typography>
        <Typography
          variant="body1"
          fontWeight={"500"}
          fontSize={14}
          mt={1}
        >
          The files were generated in {formatDuration(duration)}.
        </Typography>
      </Paper>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          sx={{
            px: 8,
          }}
          color="primary"
          onClick={generateNewModel}
        >
          Restart the process
        </Button>
      </div>
    </Box>
  );
};

export default DownloadStep;
